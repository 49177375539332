<template>
    <b-container fluid>
      <b-row :style="{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', color:'#7f7f7f'}">
        
        <b-col md="12">
          <img alt="Bufu logo" src="../assets/notfound-code.png" width="340" >
          <h1>Oops!</h1>
          <h4>HALAMAN TIDAK DITEMUKAN</h4>
          <h5>Halaman yang anda cari mungkin telah dihapus jika namanya diubah atau untuk sementara tidak tersedia</h5>
          <v-btn class="submit" rounded color="primary" dark to="/">BUKA BERANDA</v-btn>
        </b-col>
        <b-col md="12" class="d-none d-sm-block">
          <img alt="Bufu logo" src="../assets/bufu-logo-load.png" width="200"><br/>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'Login'
}
</script>
<style scoped>
.submit{
  text-decoration: none;
}

h5{
  font-size: 15px;
}

</style>